import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { StartEase } from 'scenes/Home/components/StartEase'
import { BeamStudio } from 'scenes/Home/components/BeamStudio'

import { SignUpWidget } from 'components/SignUpWidget'
import { TitleImageSideBySide } from 'components/TitleImageSideBySide'

import { LastPacks } from './components/LastPacks'

import { subRoutesNames, createAccessLink, createPricingLink } from 'services/routingService'
import { t } from 'services/i18n'
import { getConfigValue } from 'services/configService'

import './styles.scss'
import { Image } from 'components/Image'
import { Text } from 'components/Text'
import { LinkButton } from 'components/Button'

const Home = () => {
  const logedInUserId = useSelector(state => state.users.currentUser?.uid)
  const history = useHistory()

  return (
    <div>
      <div className='home'>
        <div className='home__box home__box--fullscreen home__box--primary-color'>
          <TitleImageSideBySide
            title={t('home.hero.title')}
            subTitle={t('home.hero.sub_title')}
            image={getConfigValue('home.hero.image')}
            actionButton={{
              href: createAccessLink(subRoutesNames.ACCESS.SIGN_UP),
              text: t('home.hero.action_text'),
              variant: 'outlined',
              color: 'default',
            }}
            className='home__hero'
          />
        </div>
        <div className='home__box'>
          <BeamStudio />
        </div>
        <div className='home__box home__box--fullscreen home__box--secondary-color'>
          <StartEase />
        </div>
        <div className='home__box home__box--fullscreen'>
          <Image
            className='home__fullscreen-image'
            src='https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fhome%2Fhomepage_v3%2Fgalerias_fotografos_capa.png?alt=media&token=517baa4d-2e47-44cb-ab20-0cb00ef1fefb'
            thumbnailsSpecs={[
              { size: 's', media: '(max-width: 600px)' },
              { size: 'm', media: '(min-width: 600px)' },
            ]}
          />
        </div>
        <div className='home__box home__box--fullscreen home__simple-text-cta'>
          <Text
            as='h2'
            type='title2'
          >
            {t('home.gallery-cover.title')}
          </Text>
          <LinkButton
            size='x-wide-large'
            href={createPricingLink()}
            variant='contained'
          >
            {t('home.gallery-cover.cta')}
          </LinkButton>
        </div>
        <div className='home__box home__box--fullscreen'>
          <LastPacks />
        </div>
        {!logedInUserId && (
          <div className='home__box home__box--fullscreen home__box--last'>
            <SignUpWidget history={history} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Home
