const checkRequirement = requirementData =>
  (requirementData[0] === true && requirementData[1] === true) ||
    (requirementData[0] === false && requirementData[1] === false)

export const validateRequirements = (item, logedInUser, isHomePage, isProfessionalUser) => {
  const requirementsValues = {
    logedInUser,
    homePage: isHomePage,
    isProfessionalUser,
  }

  if (item.requirements && Object.keys(item.requirements).length > 0) {
    const requirements = Object.keys(item.requirements)
      .reduce((prev, currKey) => {
        prev.push([item.requirements[currKey], requirementsValues[currKey]])
        return prev
      }, [])

    if (requirements.every(checkRequirement)) {
      return true
    }

    return false
  }

  return true
}
