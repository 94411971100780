import classNames from 'classnames'
import React from 'react'

import { BackOfficeLogoLink } from 'components/BackOfficeLogo'
import { Icon } from 'components/Icon'
import { Link } from 'components/Link'
import { List } from 'components/List'
import UserCircular from 'components/UserCircular'

import { createBlogLink, createSearchLink, createPricingLink, createAccessLink, subRoutesNames, createUserLink, createUserSettingsLink } from 'services/routingService'

import './styles.scss'

const logedInMenu = (logedInUser, closeMenu, logoutUser) => {
  return (
    <List vertical>
      <UserCircular
        className='mobile-menu__content__item mobile-menu__content__item--user'
        size={50}
        onClick={closeMenu}
        href={createUserLink(logedInUser.uid)}
        withFallback={true}
        {...logedInUser}
      />
      <Link onClick={closeMenu} href={createUserSettingsLink()} className='mobile-menu__content__item'>
        Preferências
      </Link>
      <BackOfficeLogoLink onClick={closeMenu} className='mobile-menu__content__item mobile-menu__content__item--backoffice' />
      <div className='mobile-menu__content__item mobile-menu__content__item--others'>
        Outros
        <Link onClick={closeMenu} href={createSearchLink()} className='mobile-menu__content__item'>
          Pesquisar
        </Link>
        <Link onClick={closeMenu} href={createPricingLink()} className='mobile-menu__content__item'>
          Preços
        </Link>
        <Link onClick={closeMenu} href={createBlogLink()} className='mobile-menu__content__item'>
          Blog
        </Link>
      </div>
      <div onClick={logoutUser} className='mobile-menu__content__item'>
        <Icon className='mobile-menu__content__icon'>logout</Icon>
        Sair
      </div>
    </List>
  )
}

const notLogedInMenu = closeMenu => {
  return (
    <List vertical>
      <Link onClick={closeMenu} href={createSearchLink()} className='mobile-menu__content__item'>
        Pesquisar
      </Link>
      <Link onClick={closeMenu} href={createPricingLink()} className='mobile-menu__content__item'>
        Preços
      </Link>
      <Link onClick={closeMenu} href={createBlogLink()} className='mobile-menu__content__item'>
        Blog
      </Link>
      <div className='mobile-menu__content__access-wrapper'>
        <Link onClick={closeMenu} href={createAccessLink(subRoutesNames.ACCESS.SIGN_IN)} className='mobile-menu__content__item mobile-menu__content__item--log-in'>
          Entrar
        </Link>
      </div>
      <div className='mobile-menu__content__access-wrapper'>
        <Link onClick={closeMenu} href={createAccessLink(subRoutesNames.ACCESS.SIGN_UP)} className='mobile-menu__content__item mobile-menu__content__item--register'>
          Registar
        </Link>
      </div>
    </List>
  )
}

export const MobileMenu = ({ open, logedInUser, closeMenu, logoutUser }) => {
  return (
    <div
      className={classNames('mobile-menu', {
        'mobile-menu--open': open,
      })}
    >
      <div
        className={classNames('mobile-menu__content', {
          'mobile-menu__content--open': open,
        })}
      >
        {logedInUser
          ? logedInMenu(logedInUser, closeMenu, logoutUser)
          : notLogedInMenu(closeMenu)}
      </div>
    </div>
  )
}
