import ArrowBack from '@mui/icons-material/ArrowBackIosNewOutlined'
import Menu from '@mui/material/Menu'
import classNames from 'classnames'
import React, { Fragment, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { HeaderMenuItem } from 'scenes/Header/components/HeaderMenuItem'

import { BackOfficeLogoLink } from 'components/BackOfficeLogo'
import { Link } from 'components/Link'
import { List } from 'components/List'
import { Logo } from 'components/Logo'
import { HeaderSearchBar } from 'components/Search'
import { Text } from 'components/Text'
import UserCircular from 'components/UserCircular'

import { MobileMenu } from './components/MobileMenu'
import { MobileMenuIcon } from './components/MobileMenuIcon'

import { useBreakpoints } from 'hooks/useBreakpoints'
import { useHideOnScroll } from 'hooks/useHideOnScroll'

import { USER_TYPE } from 'model/User'

import { logoutUser } from 'services/authenticationService'
import { getConfigValue } from 'services/configService'
import {
  routesNames,
  subRoutesNames,
  createSearchLink,
  dynamicLinkFactory,
  createAccessLink,
  routes,
  createBackOfficeLink,
} from 'services/routingService'
import { createPricingLink } from 'services/routingService/linkFactory'
import { createBlogLink } from 'services/routingService/linkFactory'
import { useRouteNameFromURL } from 'hooks/useRouteNameFromURL/routes'

import { getUrlQuery } from 'utils/url'
import { validateRequirements } from './utils/header-list'

import './styles.scss'

const headerList = [
  {
    link: createSearchLink(undefined, undefined, undefined, undefined, true, ['q', 'v']),
    label: 'Pesquisar',
  },
  {
    link: createPricingLink(),
    label: 'Preços',
  },
  {
    link: createBlogLink(),
    label: 'Blog',
  },
  {
    link: createAccessLink(subRoutesNames.ACCESS.SIGN_IN),
    label: 'Entra',
    requirements: {
      logedInUser: false,
    },
  },
  {
    link: createAccessLink(subRoutesNames.ACCESS.SIGN_UP, {
      signInSuccessUrl: '/register-journey',
    }),
    label: 'Regista-te',
    requirements: {
      logedInUser: false,
    },
  },
  {
    id: 'plotustudio',
    link: createBackOfficeLink(),
    label: 'PLOTUStudio',
    requirements: {
      logedInUser: true,
      isProfessionalUser: true,
    },
  },
]

const backButtonList = {
  [routesNames.BACK_OFFICE]: {
    subPages: {
      [subRoutesNames.BACK_OFFICE.EVENTS]: {
        allSubRoutes: true,
      },
      [subRoutesNames.BACK_OFFICE.CALENDAR]: {
        allRoutes: true,
      },
      [subRoutesNames.BACK_OFFICE.PACKS]: {
        allSubRoutes: true,
      },
      [subRoutesNames.BACK_OFFICE.LAYOUT]: {
        allSubRoutes: true,
      },
      [subRoutesNames.BACK_OFFICE.GALLERIES]: {
        allSubRoutes: true,
      },
      [subRoutesNames.BACK_OFFICE.GALLERIES]: {
        allSubRoutes: true,
      },
    },
  },
  [routesNames.BLOG]: {
    allSubRoutes: true,
  },
}

const parseProfileRoutes = (profileRoute, state) => {
  return {
    ...profileRoute,
    url: profileRoute.dynamicUrl && dynamicLinkFactory(profileRoute.dynamicUrl, state),
  }
}

export const Header = () => {
  const logedInUserId = useSelector(state => state.users.currentUser?.uid)
  const logedInUser = useSelector(state => logedInUserId && state.users.users[state.users.currentUser.uid])
  const { isTabletAndUp } = useBreakpoints()
  const isHidden = useHideOnScroll({ topOffset: 50, bottomOffset: 100 })

  const currentUserBtnRef = useRef(null)
  const [ userBtnMenuIsOpen, setUserBtnMenuIsOpen ] = useState(false)
  const [ mobileMenuIsOpen, setMobileMenuIsOpen ] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const isHomePage = location.pathname === routes.HOME
  const profileRoutes = getConfigValue('header.profileMenu.links')
  const parsedProfileRoutes = useSelector(
    state => profileRoutes.map(profileRoute => parseProfileRoutes(profileRoute, state))
  )
  const routeName = useRouteNameFromURL()

  const handleUserBtnClick = () => {
    if (userBtnMenuIsOpen) {
      // go to details
      history.push(`/user-details/${logedInUserId}`)
    } else {
      setUserBtnMenuIsOpen(true)
    }
  }

  const closeUserBtnMenu = () => {
    setUserBtnMenuIsOpen(false)
    return true // to change location from Link
  }

  const onLogoutUser = () => {
    closeUserBtnMenu()
    closeMobileMenu()
    logoutUser()
  }

  const getArrowBack = () => {
    const splitedPathname = location.pathname.split('/')
    const subRoute = splitedPathname[2]
    const subRoute2 = splitedPathname[3]

    if (isTabletAndUp) {
      return {
        enabled: false,
      }
    }

    const routeNameBackBtnConfig = backButtonList[routeName] || {}

    if (routeNameBackBtnConfig.withUrlQuery && getUrlQuery(routeNameBackBtnConfig.withUrlQuery)) {
      return {
        enabled: true,
        config: routeNameBackBtnConfig,
      }
    }

    if (routeNameBackBtnConfig.allRoutes && !subRoute) {
      return {
        enabled: true,
        config: routeNameBackBtnConfig,
      }
    }

    if (routeNameBackBtnConfig.allSubRoutes && subRoute) {
      return {
        enabled: true,
        config: routeNameBackBtnConfig,
      }
    }

    if (routeNameBackBtnConfig.subPages && routeNameBackBtnConfig.subPages[subRoute]) {
      const subRouteNameBackBtnConfig = routeNameBackBtnConfig.subPages[subRoute]
      if (subRouteNameBackBtnConfig.withUrlQuery && getUrlQuery(subRouteNameBackBtnConfig.withUrlQuery)) {
        return {
          enabled: true,
          config: subRouteNameBackBtnConfig,
        }
      }

      if (subRouteNameBackBtnConfig.allRoutes && !subRoute2) {
        return {
          enabled: true,
          config: subRouteNameBackBtnConfig,
        }
      }

      if (subRouteNameBackBtnConfig.allSubRoutes && subRoute2) {
        return {
          enabled: true,
          config: subRouteNameBackBtnConfig,
        }
      }
    }

    return {
      enabled: false,
    }
  }

  const toggleMobileMenu = () => setMobileMenuIsOpen(!mobileMenuIsOpen)
  const closeMobileMenu = () => setMobileMenuIsOpen(false)
  const arrowBack = getArrowBack()

  return (
    <div
      className={classNames('header', {
        'header--hidden': isHidden,
      })}
    >
      {arrowBack.enabled && (
        <Link
          href={arrowBack.config?.link}
          onClick={arrowBack.config?.link ? null : () => history.goBack()}
          className='header__back'
        >
          <ArrowBack fontSize='small' />
        </Link>
      )}
      <div className='header__logo-wrapper'>
        <Logo className='header__logo' />
      </div>
      <HeaderSearchBar />
      {!isTabletAndUp && (
        <MobileMenuIcon
          mobileMenuIsOpen={mobileMenuIsOpen}
          toggleMobileMenu={toggleMobileMenu}
        />
      )}
      {!isTabletAndUp && (
        <MobileMenu
          logedInUser={logedInUser}
          open={mobileMenuIsOpen}
          closeMenu={closeMobileMenu}
          logoutUser={onLogoutUser}
        />
      )}
      {
        isTabletAndUp && (
          <Fragment>
            <List>
              {headerList.map(item => {
                const isPlotuStudio = item.id === 'plotustudio'
                const isProfessionalUser = logedInUser?.type === USER_TYPE.PROFESSIONAL
                const requirementsAreValidated =
                  validateRequirements(item, !!logedInUserId, isHomePage, isProfessionalUser)

                if (!requirementsAreValidated || (item.link === location.pathname && !isPlotuStudio)) {
                  return null
                }

                if (isPlotuStudio) {
                  return <BackOfficeLogoLink key={item.link} className='header__item header__item--backoffice' />
                }

                return (
                  <Link
                    key={item.link}
                    href={item.link}
                    className='header__item'
                  >
                    <Text>{item.label}</Text>
                  </Link>
                )
              })}
            </List>
            {logedInUserId && (
              <Fragment>
                <div className='header__current-user__avatar-wrapper' ref={currentUserBtnRef}>
                  <UserCircular
                    size={30}
                    onClick={handleUserBtnClick}
                    noInfo
                    withFallback={true}
                    {...logedInUser}
                  />
                </div>
                <Menu
                  anchorEl={currentUserBtnRef.current}
                  keepMounted
                  open={userBtnMenuIsOpen}
                  onClose={closeUserBtnMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                >
                  {parsedProfileRoutes.map(route => {
                    return (
                      <HeaderMenuItem
                        key={route.id}
                        route={route}
                        logedInUser={logedInUser}
                        closeUserBtnMenu={closeUserBtnMenu}
                        onLogoutUser={onLogoutUser}
                      />
                    )
                  })}
                </Menu>
              </Fragment>
            )}
          </Fragment>
        )
      }
    </div>
  )
}
