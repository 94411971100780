import AddIcon from '@mui/icons-material/Add'
import classNames from 'classnames'
import React, { useState, useRef, useEffect } from 'react'

import { IconButton, LinkButton } from 'components/Button'
import { Image } from 'components/Image'
import { Text } from 'components/Text'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'

import './styles.scss'

const BeamStudioItem = ({ title, text, opened, onClick, allOpen }) => {
  const [textHeight, setTextHeight] = useState()
  const textRef = useRef(null)

  useEffect(() => {
    setTextHeight(textRef.current.offsetHeight)
  }, [textRef])

  const textStyle = {
    height: textRef.current === null || allOpen
      ? 'auto' // check initial height
      : opened
        ? `${textHeight}px`
        : '0px',
  }

  return (
    <div className='beam-studio__item'>
      <div
        onClick={onClick}
        className={classNames('beam-studio__item__header', {
          'beam-studio__item__header--all-open': allOpen,
        })}
      >
        <Text color='text__primary-color' as='h3' type='title4'>
          {title}
        </Text>
        <IconButton
          onClick={onClick}
          size='large'
          color='primary'
          className={classNames('beam-studio__item__open-button', {
            'beam-studio__item__open-button--hide': opened || allOpen,
          })}
        >
          <AddIcon />
        </IconButton>
      </div>
      <div
        ref={textRef}
        onClick={onClick}
        className={classNames('beam-studio__item__collapsible', {
          'beam-studio__item__collapsible--open': opened || textRef.current === null,
          'beam-studio__item__collapsible--all-open': allOpen,
        })}
        style={textStyle}
      >
        <Text color='text__default-color'>{text}</Text>
      </div>
    </div>
  )
}

export const BeamStudio = () => {
  const items = getConfigValue('home.beam_studio.items')
  const ctaLink = getConfigValue('home.beam_studio.cta_link')

  const [openedItemIndex, setOpenedItemIndex] = useState(0)

  const onItemClick = index => () => setOpenedItemIndex(index)

  return (
    <div className='beam-studio'>
      <Text as='h2' type='big-title' color='text__default-color' className='beam-studio__title'>{t('home.beam_studio.title')}</Text>
      <Text as='h4' type='title4' className='beam-studio__sub-title'>{t('home.beam_studio.sub_title')}</Text>
      <div className='beam-studio__content'>
        <div className='beam-studio__image-wrapper'>
          {items[openedItemIndex].image && (
            <Image
              className='beam-studio__image-wrapper__image'
              src={items[openedItemIndex].image.src}
              alt={items[openedItemIndex].image.alt}
              thumbnailsSpecs={[{ size: 's', media: '(min-width: 0px)' }]}
            />
          )}
        </div>
        <div className='beam-studio__items'>
          {
            items.map((item, index) => (
              <BeamStudioItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={onItemClick(index)}
                title={t(item.title)}
                text={t(item.text)}
                allOpen
              />
            ))
          }
          <LinkButton
            wrapperClassName='beam-studio__action-button'
            variant='outlined'
            color='primary'
            href={ctaLink}
            size='x-wide-large'
          >
            {t('home.beam_studio.action_button')}
          </LinkButton>
        </div>
      </div>
    </div>
  )
}
