import React from 'react'

import { Grid } from 'components/Grid'
import { Icon } from 'components/Icon'
import { Text } from 'components/Text'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'

import './styles.scss'

const StartEaseItem = ({ title, subTitle, content, iconId }) => (
  <div className='start-ease__item'>
    {iconId && <Icon fontSize='large' className='start-ease__item__icon'>{iconId}</Icon>}
    <Text as='h3' type='title4' bold color='text__default-color' className='start-ease__item__title'>
      {title}
    </Text>
    <Text as='p' type='subtitle2' className='start-ease__item__sub-title'>
      {subTitle}
    </Text>
    {content && (
      <Text as='p' type='body' className='start-ease__item__content' color='text__primary-color'>
        {content}
      </Text>
    )}
  </div>
)

export const StartEase = () => {
  const startEaseItems = (getConfigValue('home.start_ease.items') || [])
    .map(startEaseItem => ({
      title: t(startEaseItem.title),
      subTitle: t(startEaseItem.sub_title),
      iconId: startEaseItem.icon_id,
      content: t(startEaseItem.content),
    }))

  return (
    <div className='start-ease'>
      <Text as='h2' type='title' className='start-ease__title'>{t('home.start_ease.title')}</Text>
      <Grid className='start-ease__grid' xs={12} md={6}>
        {startEaseItems
          .map(startEaseItem => (
            <StartEaseItem
              key={startEaseItem.title}
              title={startEaseItem.title}
              subTitle={startEaseItem.subTitle}
              content={startEaseItem.content}
              iconId={startEaseItem.iconId}
            />
          ))}
      </Grid>
    </div>
  )
}
