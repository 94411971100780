export const routesNames = {
  ACCESS: 'ACCESS',
  BACK_OFFICE: 'BACK_OFFICE',
  BLOG: 'BLOG',
  GALLERY: 'GALLERY',
  HOME: 'HOME',
  PACK_DETAILS: 'PACK_DETAILS',
  PRICING: 'PRICING',
  REGISTER_JOURNEY: 'REGISTER_JOURNEY',
  SEARCH: 'SEARCH',
  STATIC_PAGES_BUILDER: 'STATIC_PAGES_BUILDER',
  USER_DETAILS: 'USER_DETAILS',
  USER: 'USER',
  USER_SETTINGS: 'USER_SETTINGS',
  FAQS: 'FAQS',
}

export const subRoutesNames = {
  ACCESS: {
    SIGN_IN: 'sign-in',
    SIGN_UP: 'sign-up',
  },
  BACK_OFFICE: {
    DASHBOARD: 'dashboard',
    CALENDAR: 'calendar',
    PACKS: 'packs',
    EVENTS: 'events',
    CLIENTS: 'clients',
    STATS: 'stats',
    GALLERIES: 'galleries',
    LAYOUT: 'layout',
  },
  USER_SETTINGS: {
    PROFILE: 'profile',
    PERSONALISATION: 'personalisation',
    PASSWORD: 'password',
    ACCOUNT: 'account',
  },
}

export const routes = {
  ACCESS: '/access/:type',
  BACK_OFFICE: '/back-office/:page?/:subPage?',
  BLOG: '/blog/:slug?',
  GALLERY: '/gallery/:id?',
  HOME: '/',
  PACK_DETAILS: '/pack-details/:id',
  REGISTER_JOURNEY: '/register-journey/:id?',
  SEARCH: '/search/:searchCategory?',
  USER_DETAILS: '/user-details/:id?',
  USER: '/:username/:tabId?',
  USER_SETTINGS: '/user-settings/:page?',
  FAQS: '/faqs',
  PRICING: '/pricing',
}
