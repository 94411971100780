import classNames from 'classnames'
import React, { useState } from 'react'

import { Button } from 'components/Button'
import { Image } from 'components/Image'
import { Link } from 'components/Link'
import { LoadingComponent } from 'components/LoadingComponent'
import { Text } from 'components/Text'
import { TextField } from 'components/TextField'

import { registerUser } from 'services/authenticationService'
import { t } from 'services/i18n'
import { createAccessLink, subRoutesNames } from 'services/routingService'

import { getErrorMessage } from 'utils/signUp'

import './styles.scss'

const BACKGROUND_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fhome%2Fhomepage_v3%2Fcall%20to%20action_registo.jpg?alt=media&token=798cd3c1-d72f-4f6c-908f-8caa1041a2e0'

export const SignUpWidget = ({ hiddenContent, history }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState()
  const [emailError, setEmailError] = useState()
  const [name, setName] = useState()
  const [nameError, setNameError] = useState()
  const [password, setPasword] = useState()
  const [passwordError, setPaswordError] = useState()

  const onEmailChange = event => {
    if (emailError) {
      setEmailError(null)
    }
    setEmail(event.target.value)
  }

  const onNameChange = event => {
    if (nameError) {
      setNameError(null)
    }
    setName(event.target.value)
  }

  const onPasswordChange = event => {
    if (passwordError) {
      setPaswordError(null)
    }
    setPasword(event.target.value)
  }

  const resetErrors = () => {
    setNameError(null)
    setEmailError(null)
    setPaswordError(null)
  }

  const onSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    resetErrors()

    if (!name) {
      const errorLabel = t('components.sign_up_widget.error.invalid_name')
      setNameError(errorLabel)
      return
    }

    if (!email) {
      setEmailError(t('components.sign_up_widget.error.invalid_email'))
      return
    }

    if (!password) {
      setPaswordError(t('components.sign_up_widget.error.invalid_password'))
      return
    }

    setIsLoading(true)
    register()
  }

  const register = () => {
    registerUser(email, password, name, history)
      .catch(setErrors)
  }

  const setErrors = error => {
    // list of errors: https://firebase.google.com/docs/auth/admin/errors

    setIsLoading(false)
    console.error(error)

    setEmailError(getErrorMessage(error))
  }

  return (
    <div className='sign-up-widget'>
      <Image
        generateThumbnailIfError={true}
        className='sign-up-widget__bg-image'
        src={BACKGROUND_IMAGE}
        thumbnailsSpecs={[
          { size: 's', media: '(max-width: 600px)' },
          { size: 'm', media: '(min-width: 600px)' },
        ]}
      />
      <div
        className={classNames('sign-up-widget__form-wrapper', {
          'sign-up-widget__form-wrapper--hidden': hiddenContent,
        })}
      >
        <h1 className='sign-up-widget__title'>{t('components.sign_up_widget.title')}</h1>
        <form className='sign-up-widget__form' onSubmit={onSubmit}>
          <div className='sign-up-widget__form__content'>
            <TextField
              value={email}
              onChange={onEmailChange}
              error={emailError}
              helperText={emailError}
              fullWidth
              label={t('components.sign_up_widget.email')}
              type='email'
              autoComplete='email'
              name='email'
            />
            <TextField
              value={name}
              onChange={onNameChange}
              error={nameError}
              helperText={nameError}
              fullWidth
              label={t('components.sign_up_widget.name')}
              type='text'
              autoComplete='cc-name'
              name='fname'
            />
            <TextField
              value={password}
              onChange={onPasswordChange}
              error={passwordError}
              helperText={passwordError}
              fullWidth
              label={t('components.sign_up_widget.password')}
              type='password'
            />
            <LoadingComponent
              isLoading={isLoading}
              size={40}
              className='sign-up-widget__loading'
              id='loader'
            />
          </div>

          <div className='sign-up-widget__form__action-buttons'>
            <Button
              className='sign-up-widget__form__submit-btn'
              type='submit'
              variant='contained'
              color='primary'
              onClick={onSubmit}
              disabled={isLoading}
            >
              {t('components.sign_up_widget.register_cta')}
            </Button>
          </div>
        </form>
        <Text className='sign-up-widget__description'>{t('components.sign_up_widget.terms_conditions_privacy_policy')}</Text>
        <Link
          className='sign-up-widget__sign-in-link'
          underlined
          href={createAccessLink(subRoutesNames.ACCESS.SIGN_IN)}
        >
          {t('components.sign_up_widget.login_link')}
        </Link>
      </div>
    </div>
  )
}
