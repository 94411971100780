import React from 'react'

import { LinkButton } from 'components/Button'
import { Image } from 'components/Image'

import './styles.scss'
import classNames from 'classnames'
import { Text } from 'components/Text'

export const TitleImageSideBySide = ({ title, subTitle, image, actionButton, className }) => {
  return (
    <div className={classNames('title-image-side-by-side', className)}>
      <div className='title-image-side-by-side__titles'>
        <Text as='h2' type='title2' className='title-image-side-by-side__title'>{title}</Text>
        <Text as='h4' type='title4' className='title-image-side-by-side__sub-title'>{subTitle}</Text>
        {actionButton && (
          <div className='title-image-side-by-side__action-button-wrapper'>
            <LinkButton
              size='x-wide-large'
              href={actionButton.href}
              variant={actionButton.variant || 'contained'}
              color={actionButton.color}
            >
              {actionButton.text}
            </LinkButton>
          </div>
        )}
      </div>

      {image && (
        <div className='title-image-side-by-side__image-wrapper'>
          <Image
            className='title-image-side-by-side__image'
            alt={image.alt}
            src={image.src}
            width={image.width}
            height={image.height}
            thumbnailsSpecs={[
              { size: 's', media: '(max-width: 600px)' },
              { size: 'm', media: '(min-width: 600px)' },
            ]}
          />
        </div>
      )}
      {/* Mobile Action Button */}
      {actionButton && (
        <div className='title-image-side-by-side__action-button-wrapper-mobile'>
          <LinkButton
            size='x-wide-large'
            href={actionButton.href}
            variant={actionButton.variant || 'contained'}
            color={actionButton.color}
          >
            {actionButton.text}
          </LinkButton>
        </div>
      )}
    </div>
  )
}
